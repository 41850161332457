import { Check } from "react-feather"
import { optionOrderStatus, tradeStatus } from "../../utils"

const CallPutData = ({ data }) => {
   return (
      <table className="call-put-table">
         <thead>
            <tr>
               <th></th>
               <th className="index">INDEX</th>
               <th className="call">CALL</th>
               <th className="put">PUT</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <th>Strike Price</th>
               <td className="index">High - {data?.index?.highLine}</td>
               <td className="call">{data?.call?.brokerDetail?.tradingsymbol?.slice(-7, -2)}</td>
               <td className="put">{data?.put?.brokerDetail?.tradingsymbol?.slice(-7, -2)}</td>
            </tr>
            <tr>
               <th>Trading Symbol</th>
               <td className="index">CLOSE 9:20 - {data?.index?.closeLine}</td>

               <td className="call">{data?.call?.brokerDetail?.tradingsymbol}</td>
               <td className="put">{data?.put?.brokerDetail?.tradingsymbol}</td>
            </tr>
            <tr>
               <th>Expiry</th>
               <td className="index">Low - {data?.index?.lowLine}</td>

               <td className="call">
                  {" "}
                  {data?.call?.brokerDetail?.expiry.slice(0, 2)} {data?.call?.brokerDetail?.expiry?.slice(2, 5)}{" "}
                  {data?.call?.brokerDetail?.expiry.slice(5, 9)}
               </td>
               <td className="put">
                  {data?.put?.brokerDetail?.expiry.slice(0, 2)} {data?.put?.brokerDetail?.expiry?.slice(2, 5)}{" "}
                  {data?.put?.brokerDetail?.expiry.slice(5, 9)}
               </td>
            </tr>
            <tr>
               <th>ltp</th>
               <td
                  className="index"
                  style={{ color: data?.index?.ltp > data?.index?.highLine ? "green" : data?.index?.ltp < data?.index?.lowLine ? "red" : "#be8f00" }}
               >
                  {data?.index?.ltp}
               </td>
               <td className="call">{data?.call?.ltp}</td>
               <td className="put">{data?.put?.ltp}</td>
            </tr>
            <tr>
               <th>Trigger Value</th>
               <td className="index" style={{
                     border: data.lastIndexCloseStatus === "highLine" ? "5px solid green" : ""
                  }}>
                  CALL - {data?.index?.triggerValueForBuy}{" "}
                  {data.lastIndexCloseStatus === "highLine" && <Check size={16} color="green" strokeWidth={5} />}
               </td>
               <td className="call">{data?.call?.triggerValueForBuy}</td>
               <td className="put">{data?.put?.triggerValueForBuy}</td>
            </tr>
            <tr>
               <th>Target Value</th>
               <td
                  className="index"
                  style={{
                     border: data.lastIndexCloseStatus === "lowLine" ? "5px solid red" : ""
                  }}
               >
                  PUT - {data?.index?.triggerValueForShortSideBuy}
                  {data.lastIndexCloseStatus === "lowLine" && <Check size={16} color="red" strokeWidth={5} />}
               </td>
               <td className="call">{data?.call?.targetValue}</td>
               <td className="put">{data?.put?.targetValue}</td>
            </tr>
            <tr>
          <th>Day High</th>
          <td className="index">High Max- {data?.index?.highLineMax}</td>
          <td className="call">{data?.call?.dayHigh}</td>
          <td className="put">{data?.put?.dayHigh}</td>
        </tr>
            <tr>
               <th>Stop Loss Value</th>
               <td className="index">Low Max- {data?.index?.lowLineMax}</td>
               <td className="call">{data?.call?.stopLossValue.toFixed(2)}</td>
               <td className="put">{data?.put?.stopLossValue.toFixed(2)}</td>
            </tr>
            <tr>
               <th>Lot Size</th>
               <td className="index">----</td>
               <td className="call">{data?.call?.brokerDetail?.lotsize}</td>
               <td className="put">{data?.put?.brokerDetail?.lotsize}</td>
            </tr>

            <tr>
               <th>Option Status</th>
               <td className="index">----</td>
               <td className="call">{tradeStatus[data.call?.isActive]}</td>
               <td className="put">{tradeStatus[data.put?.isActive]}</td>
            </tr>
            <tr>
               <th>Order Status</th>
               <td className="index">{data.lastIndexCloseStatus}</td>
               <td className="call">{optionOrderStatus[data.call?.orderStatus]}</td>
               <td className="put">{optionOrderStatus[data.put?.orderStatus]}</td>
            </tr>
         </tbody>
      </table>
   )
}

export default CallPutData
