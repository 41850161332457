// ** React Imports
import { Fragment, useState, forwardRef } from "react"
import Flatpickr from "react-flatpickr"
import ReactPaginate from "react-paginate"
import DataTable from "react-data-table-component"
import { ChevronDown, MoreVertical, Edit3, PlusCircle, XCircle, Activity, User, ChevronLeft, ChevronRight } from "react-feather"
import Select from "react-select"
import alarm from "../../../src/tunes/alarm.wav"
// ** Reactstrap Imports
import { Card, Input, Button, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { customStyles, isCurrentDayInRange, isCurrentTimeInRange } from "../../utils"
import { useEffect } from "react"
// import { data } from "../../../../utility/genericServicesData"
// import { genericServicesColumnList } from "../genericServicesOptions"
// import { getGenericServiceAll } from "../../../../utility/myGenericServiceApiCenter"
import { useContext } from "react"
import { stockColumnList } from "../tableColumn"
import { AuthContext, StretagyContext } from "../../context/Can"
import { dummyDataForTable } from "../../dummyData"
import { getAllStock, getAllStr, getClose, postForceStopbyStrId, postRestartSocketbyStrId } from "../../myApiCenter"
import MainNav from "../MainNav/MainNav"
import StockTradeModal from "./StockTradeModal"
import toast from "react-hot-toast"
import ErrorUserModal from "./ErrorUserModal"
import CallPutData from "./CallPutData"
import RefreshToast from "../Componant/refreshToast"

const StrList = () => {
   const navigate = useNavigate()
   const [, idListSetter] = useContext(StretagyContext)
   const [currentPage, setCurrentPage] = useState(0)
   const [rowsPerPage, setRowsPerPage] = useState(10)
   const [data, setdata] = useState([])
   const [userData, userDataSetter] = useContext(AuthContext)
   const [errorUser, seterrorUser] = useState([])
   const [isErrorUserModal, setisErrorUserModal] = useState(false)
   const [isTradeModal, setisTradeModal] = useState(false)
   const [stockDetail, setstockDetail] = useState(null)
   const getData = async () => {
      try {
         const j = await getAllStr()
         // console.log(j)
         setdata(j?.data?.str)
         const idList = j?.data?.str.map((e, index) => {
            return { serial: index + 1, id: e._id }
         })
         localStorage.setItem("stretagyIdList", JSON.stringify(idList))
         idListSetter(idList)
      } catch (error) {
         console.log(error)
      }
   }

   const columns = [
      ...stockColumnList,
      {
         name: "Action",
         allowoverflow: true,
         minWidth: "70px",
         maxWidth: "70px",
         cell: (row) => {
            if (!row.isSocketConnected && isCurrentTimeInRange() && row.isTradeFound) {
               new Audio(alarm).play()
               toast.dismiss()
               toast.custom(<RefreshToast title={row.name} onClick={() => toast.dismiss()} />, {
                  duration: 8000,
                  style: {
                     borderTopLeftRadius: "250px"
                  }
               })
            }

            return (
               <div className="d-flex">
                  <UncontrolledDropdown>
                     <DropdownToggle style={{ cursor: "pointer" }} className="pe-1" tag="span">
                        <MoreVertical size={15} />
                     </DropdownToggle>
                     <DropdownMenu end>
                        <DropdownItem
                           tag="div"
                           className="w-100"
                           onClick={() => {
                              navigate(`/str/edit/${row._id}`)
                           }}
                        >
                           <Edit3 size={24} />
                           <span className="align-middle ms-50">Edit Info</span>
                        </DropdownItem>

                        <DropdownItem
                           tag="div"
                           className="w-100"
                           onClick={() => {
                              // console.log(row)
                              postRestartSocketbyStrId(row?._id)
                           }}
                        >
                           <Edit3 size={24} />
                           <span className="align-middle ms-50">Restart Server</span>
                        </DropdownItem>
                        <DropdownItem
                           tag="div"
                           className="w-100"
                           onClick={() => {
                              // console.log(row)
                              postForceStopbyStrId(row?._id)
                           }}
                        >
                           <Edit3 size={24} />
                           <span className="align-middle ms-50">Force Stop For Today</span>
                        </DropdownItem>
                        <DropdownItem
                           tag="div"
                           className="w-100"
                           onClick={() => {
                              navigate(`/tradelog/${row._id}`)
                           }}
                        >
                           <Edit3 size={24} />
                           <span className="align-middle ms-50">Statics</span>
                        </DropdownItem>
                     </DropdownMenu>
                  </UncontrolledDropdown>
               </div>
            )
         }
      }
   ]

   // ** Function to handle Pagination
   const handlePagination = (page) => {
      setCurrentPage(page.selected)
   }
   const handlePerPage = (e) => {
      setCurrentPage(0)
      setRowsPerPage(parseInt(e.target.value))
   }
   useEffect(() => {
      // Store interval ID so we can clear it later
      const intervalId = setInterval(() => {
         if (isCurrentDayInRange()) {
            getData()
         }
         getData()
      }, 1000)
      getData()
      // Clear interval on component unmount
      return () => {
         clearInterval(intervalId)
      }
   }, [])
   // console.log(data)
   // ** Custom Pagination
   const ExpandableTable = ({ data }) => {
      return (
         <div className="expandable-content p-2">
            <CallPutData data={data} />
         </div>
      )
   }
   const CustomPagination = (props) => {
      return (
         <div className="d-flex mt-3 justify-content-between">
            <div>
               {currentPage * rowsPerPage + 1} TO {(currentPage + 1) * rowsPerPage < data.length ? (currentPage + 1) * rowsPerPage : data.length} of{" "}
               {data.length} items
            </div>
            <div className="d-flex gap-3">
               <ReactPaginate
                  previousLabel={<ChevronLeft size={18}   />}
                  nextLabel={<ChevronRight size={18} />}
                  forcePage={data.length > rowsPerPage ? currentPage : 0}
                  onPageChange={(page) => handlePagination(page)}
                  pageCount={Math.ceil(data.length / rowsPerPage) || 1}
                  breakLabel="..."
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={2}
                  activeClassName="active"
                  pageClassName="page-item"
                  breakClassName="page-item"
                  nextLinkClassName="page-link"
                  pageLinkClassName="page-link"
                  breakLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextClassName="page-item next-item"
                  previousClassName="page-item prev-item"
                  containerClassName="pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1"
               />

               <div>
                  <Input
                     type="select"
                     id="rows-per-page"
                     value={rowsPerPage}
                     onChange={(e) => {
                        props.onChangeRowsPerPage(e.target.value)
                        handlePerPage(e)
                     }}
                     className="page-selector"
                  >
                     <option value="5">5</option>
                     <option value="7">7</option>
                     <option value="10">10</option>
                     <option value="15">15</option>
                     <option value="25">25</option>
                     <option value="50">50</option>
                  </Input>
               </div>
            </div>
         </div>
      )
   }

   // console.log(data)

   return (
      <Fragment>
         <MainNav />
         <Modal isOpen={isTradeModal} toggle={() => setisTradeModal(!isTradeModal)} className="modal-dialog-centered modal-lg">
            <div className="d-flex justify-content-between w-100 p-2">
               <h4> {stockDetail?.path === "place-order-for-all" ? "Place Order For All" : "Square Off Trade"} </h4>
               <XCircle
                  style={{ cursor: "pointer" }}
                  className="cursor-pointer"
                  onClick={() => {
                     setisTradeModal(false)
                     setstockDetail(null)
                     toast.dismiss()
                  }}
               />
            </div>

            <StockTradeModal setisModelOpen={setisTradeModal} stockDetail={stockDetail} />
         </Modal>
         <Modal isOpen={isErrorUserModal} toggle={() => setisErrorUserModal(!isErrorUserModal)} className="modal-dialog-centered modal-lg">
            <div className="d-flex justify-content-between w-100 p-2">
               <h4> Error User List </h4>
               <XCircle
                  style={{ cursor: "pointer" }}
                  className="cursor-pointer"
                  onClick={() => {
                     setisErrorUserModal(false)
                     setstockDetail(null)
                     toast.dismiss()
                  }}
               />
            </div>
            <ErrorUserModal setisModelOpen={setisErrorUserModal} stockDetail={stockDetail} />
         </Modal>
         <Card className="mt-5 ms-3 main-table">
            <div className="react-dataTable react-dataTable-selectable-rows">
               <DataTable
                  customStyles={customStyles}
                  pagination
                  columns={columns}
                  paginationPerPage={rowsPerPage}
                  className="react-dataTable"
                  sortIcon={<ChevronDown size={10} />}
                  paginationComponent={CustomPagination}
                  paginationDefaultPage={currentPage + 1}
                  data={data}
                  expandableRows
                  expandOnRowClicked
                  conditionalRowStyles={[
                     {
                        when: (row) => row.isTradeDay, // Replace this with your condition
                        style: {
                           backgroundColor: "rgb(169 228 242 / 72%)",
                           color: "white",
                           "&:hover": {
                              cursor: "pointer"
                           }
                        }
                     }
                  ]}
                  expandableRowsComponent={ExpandableTable}
                  subHeader={true}
                  subHeaderComponent={
                     <div className="d-flex p-1 w-100 justify-content-between align-items-center">
                        <h1 className="datatable-title-head">STRETAGIES</h1>
                        <div className="d-flex gap-1 align-items-center">
                           <Button className="my-btn" onClick={() => navigate("/str/add")}>
                              <PlusCircle />
                              &nbsp; Add New Stretagy
                           </Button>
                        </div>
                     </div>
                  }
               />
            </div>
         </Card>
      </Fragment>
   )
}
export default StrList
