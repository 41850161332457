import { NavLink } from "react-router-dom"
import { AuthContext } from "../../context/Can"
import { useContext } from "react"
import "./MainNav.css"
const MainNav = () => {
   // const token = gettoken()
   const [userData, userDataSetter] = useContext(AuthContext)

   // console.log(userData)
   return (
      <nav role="navigation">
         <div id="menuToggle">
            <input type="checkbox" />
            <span></span>
            <span></span>
            <span></span>
            <ul id="menu">
               {userData?.login && (
                  <li className="d-block ">
                     <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={"/dashboard"}>
                        DASHBOARD 
                     </NavLink>
                  </li>
               )}
               <li className="d-block ">
                  <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={"/about"}>
                     ABOUT US
                  </NavLink>
               </li>
               <li className="d-block ">
                  <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={"/contact"}>
                     CONTACT US
                  </NavLink>
               </li>
               {(userData?.userRole === "admin" || userData?.userRole === "manager") && (
                  <li className="d-block ">
                     <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={"/unlogged-user"}>
                        UNLOGGED USER
                     </NavLink>
                  </li>
               )}
               {/* {userData?.userRole === "admin" && (
               <li className="d-block ">
                  <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={"/stocks"}>
                     STOCKS
                  </NavLink>
               </li>
            )} */}
               {userData?.userRole === "admin" && (
                  <li className="d-block ">
                     <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={"/str"}>
                        STRATEGY
                     </NavLink>
                  </li>
               )}
               {(userData?.userRole === "admin" || userData?.userRole === "manager") && (
                  <li className="d-block ">
                     <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={"/tradelog"}>
                        TRADE LOG
                     </NavLink>
                  </li>
               )}
               {userData?.userRole === "admin" && (
                  <li className="d-block ">
                     <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={"/users"}>
                        USERS
                     </NavLink>
                  </li>
               )}
               {!userData?.login && (
                  <li className="d-block ">
                     <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={"/signup"}>
                        SIGN UP
                     </NavLink>
                  </li>
               )}
               {userData?.login ? (
                  <li className="d-block ">
                     <NavLink onClick={() => userDataSetter({})} className={({ isActive }) => (isActive ? "active" : "")} to={"/login"}>
                        LOG OUT
                     </NavLink>
                  </li>
               ) : (
                  <li className="d-block ">
                     <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={"/login"}>
                        LOG IN
                     </NavLink>
                  </li>
               )}
            </ul>
         </div>
      </nav>
   )
}

export default MainNav
